.mainfooter {
  
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;

  .greenscreamlogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    img {
      width: 50px;
      height: 50px;
      margin-left: 20px;
    }
    span {
      font-size: 20px;
      font-family: "Lato", sans-serif;
      font-weight: 100;
      font-style: normal;
    }
  }
}
