.signup-wrapper {
  width: 100vw;
  height: 100vh;
  padding: 40px;
  display: flex;

  .img-left {
    width: 70%;
    height: 100%;
    border-radius: 50px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.2);
    
    img {
      width: 100%;
      height: 100%;
      
      object-fit: cover; /* Skaluje obraz, aby wypełnić kontener */
      object-position: left; /* Ustawia pozycję obrazu na lewo */
      background-repeat: no-repeat;
    }
  }

  .sign-up-page {
    position: relative;
    display: flex;
    flex-direction: column;
    
    width: 35%;
    align-items: center;
    justify-content: center;

    .arrowBack{
      position: absolute;
      top: 40px;
      left: 40px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      
    }

    h2 {
      font-family: "Merriweather", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 36px;
    }

    .signup-email {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-bottom: 25px;
      margin-top: 45px;

      .main_button {
        width: 300px;
      }

      input
       {
        width: 300px;
        padding: 15px 20px;
        
        border: none;
        border-bottom: 1px solid rgb(161, 161, 161);
        font-family: "Lato", serif;
        font-weight: 300;
        font-style: normal;
        font-size: 16px;
      }
      
    }
    .signup-login {
        margin-top: 50px;
        font-family: "Lato", serif;
        font-weight: 300;
        font-style: normal;
        font-size: 16px;
        button {
          border: none;
          background-color: transparent;
          margin-left: 10px;
          color: rgb(108, 189, 107);
          cursor: pointer;
        }
      }
  }
}

@media (max-width: 680px) {
  .signup-wrapper {
    width: 100%;
    .img-left {
      display: none;
    }
    .sign-up-page{
      width: 100%;

      .arrowBack{
        top: -20px;
        left: 0;
      }
    }
  }
}
