.admindashboard{

    table{
        border: 1px solid black;
        thead{
            
            tr{
                
                th{
                    
                }
            }
        }
    }

    .logout-btn{
        border: 1px solid black;
        width: fit-content;
        padding: 15px 25px;
        cursor: pointer;

    }
    .mainPage-btn{
        border: 1px solid black;
        width: fit-content;
        padding: 15px 25px;
        cursor: pointer;
    }
}