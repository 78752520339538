.admin-panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .posts-list, .post-form {
      margin-bottom: 40px;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }
  
      th {
        background-color: #f4f4f4;
      }
    }
  
    form div {
      margin-bottom: 15px;
  
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input, textarea, select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  
    button {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  