.footer {
  position: relative;
  width: 100%;
  padding: 50px 40px 250px;

  background-image: url("../../../public/images/design_footer.png");
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: "Lato", serif;
    font-weight: 300;
    font-size: 20px;

    h1 {
      font-family: "Merriweather", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 40px;
      margin-bottom: 30px;
    }

    .adres {
    }
    .audytors {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .audytor_prze,
      .audytor_mar{
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
